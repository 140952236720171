import { computed, ref } from '@vue/composition-api'

import { mdiShield } from '@mdi/js'
import { POSITION, TYPE } from 'vue-toastification'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@/components/ToastificationContent.vue'

// Dependencies
// import useUser from '@/services/api/modules/user/user'
import useUserSubscription from '@/services/api/modules/userSubscription'

//
/* *** PushNotificationCard *** */
export function useUserNotificationCard() {
  // pushNotificationCardHide => El usuario ha cerrado la tarjeta de Activar notificaciones desde el inicio
  // pushNotificationDisabled => El usuario ha desactivado las notificaciones (Activado = false)
  // showPushNotificationCard: Indica si se muestra la tarjeta
  //   const pushNotificationCardHide = ref(localStorage.getItem('pushNotificationCardHide') || false)
  //   const pushNotificationDisabled = ref(localStorage.getItem('pushNotificationsDisable') || false)
  // const showPushNotificationCard = ref(!pushNotificationCardHide.value && pushNotificationDisabled.value)

  //   const pushNotificationCardHideLS = localStorage.getItem('pushNotificationCardHide') || false
  //   const pushNotificationDisabledLS = localStorage.getItem('pushNotificationsDisable') || false
  //   const pushNotificationCardHide = ref(pushNotificationCardHideLS)
  //   const pushNotificationDisabled = ref(pushNotificationDisabledLS)

  const setPushNotificationCardHide = value => {
    if (value) {
      localStorage.setItem('pushNotificationCardHide', true)
    } else {
      localStorage.removeItem('pushNotificationCardHide')
    }
  }
  const setPushNotificationsDisable = value => {
    if (value) {
      localStorage.setItem('pushNotificationsDisable', true)
    } else {
      localStorage.removeItem('pushNotificationsDisable')
    }
  }

  const pushNotificationCardHide = computed({
    get: () => localStorage.getItem('pushNotificationCardHide') || false,
    set: val => {
      setPushNotificationCardHide(val)
    },
  })
  const pushNotificationDisabled = computed({
    get: () => localStorage.getItem('pushNotificationsDisable') || false,
    set: val => {
      setPushNotificationsDisable(val)
    },
  })

  //   const showPushNotificationCard = computed(() => !pushNotificationCardHide.value && pushNotificationDisabled.value)
  //   const showPushNotificationCard = ref(!pushNotificationCardHide.value && pushNotificationDisabled.value)
  const showPushNotificationCard = ref(!pushNotificationCardHide.value)

  return {
    setPushNotificationCardHide,
    setPushNotificationsDisable,
    pushNotificationCardHide,
    pushNotificationDisabled,
    showPushNotificationCard,
  }
}

//
/* *** UserSubscription *** */
// PUSH NOTIFICATIONS
//  Documentation:  https://www.notion.so/emprotic/Web-Push-Notification-c6e1dc1104754c598d586cd8a62206b2
//  Code lab:       https://web-push-codelab.glitch.me/
export function useUserNotificationHandler(props) {
  // Notification
  const toast = useToast() // Same interface as this.$toast

  // ------------------------------------------------
  // (UI) Notification Toast
  // ------------------------------------------------
  const sendNotificationToast = (title, variant = TYPE.ERROR, icon = mdiShield) => {
    // toast.warning(`Campaign Inbound ${action}`)
    toast(
      {
        component: ToastificationContent,
        props: {
          title: `WebPush - ${title}`,
          variant,
          icon,
        },
      },
      { position: POSITION.BOTTOM_CENTER, type: variant },
    )
  }
  const { setPushNotificationCardHide, setPushNotificationsDisable } = useUserNotificationCard()

  // To view converter .....
  const converter = user => ({
    followMe: false,
    newAnnouncements: false,
    pushNotification: {
      active: false,
      userSubscription: null,
      userSubscriptionOptions: [], // ['imbounds', 'chats']
      subscriptions: user?.subscriptions || [],
    },
  })
  const userNotificationLocal = ref(converter(props.user.value))

  // Actualizar la subscipción en el servidor (También actualizamos la UI(Checkbox))
  const setCurrentSubscriptionOnUI = subscriptionNav => {
    // Identificar la subscripcion en la lista (Por el ENDPOINT)
    if (subscriptionNav) {
      const userSubscription = userNotificationLocal.value.pushNotification.subscriptions.find(
        uS => uS.subscription.endpoint === subscriptionNav.endpoint,
      )
      if (userSubscription) {
        userNotificationLocal.value.pushNotification.active = true
        userNotificationLocal.value.pushNotification.userSubscription = userSubscription
      } else {
        // No se ha encontrado ==> La suscripcion ha sido eliminada por otra vías (Otro navegador o manualmente)
        userNotificationLocal.value.pushNotification.active = false
        userNotificationLocal.value.pushNotification.userSubscription = null

        // Opcion 1: Eliminar suscripción del servidor (El usuario decidirá si lo vuelve a hacer)
        // eslint-disable-next-line no-use-before-define
        // unsubscribeUser(null) // Eliminar suscripcion del navegador (sin registrar en servidor)

        // Opcion 2: Crear la suscripción del servidor (Mantenemos al usuario conectado)
        //  Por contra puede pasar que esta subscripcion sea de otro usuario que se registró previamente
        // eslint-disable-next-line no-use-before-define
        createUserSubscriptionOnServer(subscriptionNav)
      }
    } else {
      userNotificationLocal.value.pushNotification.active = false
      userNotificationLocal.value.pushNotification.userSubscription = null
    }
  }

  // Create a new UserSubscription
  const createUserSubscriptionOnServer = (subscriptionNav, options = []) => {
    useUserSubscription
      .createUserSubscription({
        pushSubscriptionString: JSON.stringify(subscriptionNav), // Send JSON string
        flavors: options,
      })
      .then(response => {
        const { userSubscription } = response.data
        if (userSubscription) {
          // Add new UserSubscription
          userNotificationLocal.value.pushNotification.subscriptions.push(userSubscription)
          userNotificationLocal.value.pushNotification.active = true
          userNotificationLocal.value.pushNotification.userSubscription = userSubscription
        }
        sendNotificationToast('Subscribed', 'success')
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.error) {
          sendNotificationToast(error.response.data.error.message, 'error')
        }
      })
  }

  // Delete current subscription
  const deleteUserSubscriptionOnServer = userSubscriptionId => {
    useUserSubscription
      .removeUserSubscription(userSubscriptionId)
      .then(() => {
        // Remove from list
        userNotificationLocal.value.pushNotification.subscriptions = userNotificationLocal.value.pushNotification.subscriptions.filter(uS => uS.id !== userSubscriptionId)

        // Remove from current
        userNotificationLocal.value.pushNotification.active = false
        userNotificationLocal.value.pushNotification.userSubscription = null

        sendNotificationToast('Deleted')
      })
      .catch(error => {
        if (error.response && error.response.data && error.response.data.error) {
          sendNotificationToast(error.response.data.error.message, 'error')
        }
      })
  }

  /* >>> NOTIFICATION PUSH */
  const applicationServerPublicKey = 'BG3m3lp024rrSCcAZLAYX2-OrOxOMJt0SWh_OxdxJMVOz-BjuPYnGj7hgpmezSYb5tNagG9I5JU3evGNgubYwb8'
  let pushNotificationSWRegistration = null

  // let pushNotificationSubscription = null

  const urlB64ToUint8Array = base64String => {
    // eslint-disable-next-line no-mixed-operators
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i)
    }

    return outputArray
  }

  // Inicializar
  // Leer el Service Worker que ha sido registrado por el navegador
  // Leer el estado de la suscripcion
  const checkSubscription = () => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .getRegistration()
        .then(registration => {
          // Checking... Si no tiene ServiceWorker fallará
          pushNotificationSWRegistration = registration
          pushNotificationSWRegistration.pushManager.getSubscription().then(subscriptionNav => {
            setCurrentSubscriptionOnUI(subscriptionNav) // Only UI (Not server)
          })
        })
        .catch(error => {
          // Informamos al usuario de forma genérica y dejamos log
          console.log('Service Worker Error', error)
          sendNotificationToast('Service Worker Error', 'error') // error.message
        })
    } else {
      sendNotificationToast('Notification Not supported at this navigator', 'error')
    }
  }
  checkSubscription()

  // Subscribir usuario
  const subscribeUser = () => {
    const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey)
    pushNotificationSWRegistration.pushManager
      .subscribe({
        userVisibleOnly: true,
        applicationServerKey,
      })
      .then(subscriptionNav => {
        // console.log('User is subscribed:', subscriptionNav)
        createUserSubscriptionOnServer(subscriptionNav)

        // Inicializamos: (Se elimina) Así nos recordara si algún dia anulamos subscripcion
        //  hidePushNotificationsCard => Indica que el usuario ha cerrado el recordatorio
        // eslint-disable-next-line no-use-before-define
        setPushNotificationCardHide(true)
        setPushNotificationsDisable(false)
      })
      .catch(error => {
        // Informamos al usuario de forma genérica y dejamos log
        console.log('Service Worker Error', error)
        if (error.response && error.response.data && error.response.data.error) {
          sendNotificationToast(error.response.data.error.message, 'error')
        }
      })
  }

  // Eliminar subscripcion
  //    userSubscriptionId == <id>    ==> Elimina subscripción en Navegador y en servidor
  //    userSubscriptionId == null    ==> Solo elimina en Navegador (En servidor ya no existe)
  const unsubscribeUser = (userSubscriptionId = null) => {
    pushNotificationSWRegistration.pushManager
      .getSubscription()
      .then(subscriptionNav => {
        if (subscriptionNav) {
          subscriptionNav.unsubscribe().then(() => {
            if (userSubscriptionId) {
              deleteUserSubscriptionOnServer(userSubscriptionId)
            }

            // Indicadores Home (Call to action)
            setPushNotificationCardHide(false)
            setPushNotificationsDisable(true)
          })
        }
      })
      .catch(error => {
        // Informamos al usuario de forma genérica y dejamos log
        console.log('Error unsubscribing', error)
        sendNotificationToast('Service Worker Error', 'error') // error.message
      })
  }

  /* *** UI *** */

  // User click at the button: REMOVE ALL SUBSCRIPTIONS
  const onClickPushNotificationDeleteAll = () => {
    useUserSubscription
      .removeUserSubscriptionAll()
      .then(() => {
        // Subscriones eliminadas del servidor
        userNotificationLocal.value.pushNotification.active = false
        userNotificationLocal.value.pushNotification.userSubscription = null
        userNotificationLocal.value.pushNotification.subscriptions = []

        // eslint-disable-next-line no-use-before-define
        unsubscribeUser(null) // Eliminar suscripcion sin registrar en servidor

        // Indicadores Home (Call to action)
        setPushNotificationCardHide(false)
        setPushNotificationsDisable(true)

        sendNotificationToast('All removed')
      })
      .catch(error => {
        // toast.error(error.message)
        if (error.response && error.response.data && error.response.data.error) {
          sendNotificationToast(error.response.data.error.message, 'error')
        }
      })
  }

  // User click  at Toggle PushNotification: Params checked not used
  const onClickPushNotificationToggle = () => {
    userNotificationLocal.value.pushNotification.active = false

    // Checking... El navegador ha cargado el Service Worker
    if (pushNotificationSWRegistration) {
      // Toggle subscription
      if (userNotificationLocal.value.pushNotification.userSubscription == null) {
        subscribeUser()
      } else {
        unsubscribeUser(userNotificationLocal.value.pushNotification.userSubscription.id)
      }
    } else {
      sendNotificationToast('Service Worker Error', 'error')
    }
  }

  /* <<< NOTIFICATION PUSH */

  /* *** UI *** */
  const userSubscriptionOptionsFilter = [
    { label: 'Inbounds', value: 'inbounds' },
    { label: 'Chats', value: 'chats' },
  ]

  return {
    userNotificationLocal,

    // UI
    userSubscriptionOptionsFilter,

    // Actions
    onClickPushNotificationToggle,
    onClickPushNotificationDeleteAll,
  }
}
