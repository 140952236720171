import axios from '@axios'
import store from '@/store'

// import BaseApiService from '../../apiBase'

// User Router list
// GET   /users                  Retrieves a User resource.
// POST  /users/media            Upload a ProductSupplier image. (multipart/form-data only in POST or GET)
// PUT   /users/general          Replaces a User resource (General).
// PUT   /users/notification     Replaces a User resource (Notification).
// PUT   /users/social-links     Replaces a User resource (Social).
// PUT   /users/reset-password   Replaces a User resource (Social).
const routeUsers = '/users'

// URL connection
//  8060 -> symfony API
//  8062 -> @fake-db
const API_BASE_MODULE = process.env.VUE_APP_API_BASE

// const API_BASE_MODULE = '' // @fake-db
// const API_BASE_MODULE = undefined // Take default BaseApiService

// Si extendemos duplicamos instancias axios
// class User extends BaseApiService { constructor() { super(axios)}
class User {
  axiosIns = axios

  API_BASE = API_BASE_MODULE !== undefined ? API_BASE_MODULE : this.baseUrl

  // To view converter ..... Esto no tiene sentido aquí pero si en la vista
  converter = userData => {
    const account = {
      general: {
        avatar: userData.avatar,
        firstName: userData.firstName,
        lastName: userData.lastName,
        language: userData.language,
        phone: userData.phone,
        email: userData.email,
        companyName: userData.company?.general?.name,
      },
      social: {
        socialLinks: {
          telegram: userData.telegram,
          whatsapp: userData.whatsapp,
          instagram: userData.instagram,
          facebook: userData.facebook,
          linkedin: userData.linkedin,
        },
        connections: [],
      },
      notification: {
        followMe: false,
        newAnnouncements: false,
        pushNotification: true,
      },
    }

    return { ...account }
  }

  fetchUser() {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeUsers}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  fetchAnalytics() {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeUsers}/analytics`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // >>> TODO - Creo que ya no funciona
  update(...args) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(`${this.API_BASE}/user/update`, ...args)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // TODO - Creo que ya no funciona (Se ha copiado del antigua API)
  // Update user data: SHORTCUTS,...
  updateUserShortcuts(...args) {
    // debugger
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(`${this.API_BASE}/user/update`, ...args)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // TODO - Creo que ya no funciona (No estaba desarrollado en la API)
  updateAccountSettingsInformation(...args) {
    // debugger
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(`${this.API_BASE}/user/update`, ...args)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // <<< TODO

  updateUserMedia(formData) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(`${this.API_BASE}${routeUsers}/media`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
        .then(response => {
          const { user } = response.data
          if (user) {
            store.commit('user/SET', user)
          }

          return resolve(response)
        })
        .catch(error => reject(error))
    })
  }

  updateUserGeneral(userData) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .put(`${this.API_BASE}${routeUsers}/general`, { ...userData })
        .then(response => {
          const { user } = response.data
          if (user) {
            store.commit('user/SET', user) // update all data
          }

          return resolve(response)
        })
        .catch(error => reject(error))
    })
  }

  updateUserSocialLinks(userData) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .put(`${this.API_BASE}${routeUsers}/social-links`, { ...userData })
        .then(response => {
          const { user } = response.data
          if (user) {
            // store.commit('user/ACCOUNT', this.converter(user))
            store.commit('user/SET', user) // update all data
          }

          return resolve(response)
        })
        .catch(error => reject(error))
    })
  }

  updateUserNotification(userData) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .put(`${this.API_BASE}${routeUsers}/notification`, { ...userData })
        .then(response => {
          const { user } = response.data
          if (user) {
            // store.commit('user/ACCOUNT', this.converter(user))
            store.commit('user/SET', user) // update all data
          }

          return resolve(response)
        })
        .catch(error => reject(error))
    })
  }

  // Reset Password (Requiere authentificacion)
  // args = { passwordOld, passwordNew, passwordRetype } => { userData }
  resetPassword(args) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .put(`${this.API_BASE}${routeUsers}/reset-password`, { ...args })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

// Exportamos useProductSupplier (Antes se exportaba la clase y se definia un fichero nuevo para exportar la instancia)
const useUser = new User()
export default useUser
