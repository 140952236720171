import axios from '@axios'

// import BaseApiService from '../apiBase'

// interface UserSubscriptionInterface {
//     id: string;
//     name: string;
// }

// UserSubscription Router list
// GET    /user/subscriptions             Retrieves the collection of UserSubscription resources (By user).
// POST   /user/subscriptions             Creates a UserSubscription resource.
// GET    /user/subscriptions/{id}        Retrieves a UserSubscription resource.
// PUT    /user/subscriptions/{id}        Replaces a UserSubscription resource.
// DELETE /user/subscriptions             Removes all UserSubscription resource (By user)
// DELETE /user/subscriptions/{id}        Removes a UserSubscription resource.
const routeUserSubscriptions = '/user/subscriptions'

// URL connection
//  8060 -> symfony API
//  8062 -> @fake-db
const API_BASE_MODULE = process.env.VUE_APP_API_BASE

// const API_BASE_MODULE = '' // @fake-db
// const API_BASE_MODULE = undefined // Take default BaseApiService

// Si extendemos duplicamos instancias axios
// class UserSubscription extends BaseApiService { constructor() { super(axios) }
class UserSubscription {
  axiosIns = axios

  API_BASE = API_BASE_MODULE !== undefined ? API_BASE_MODULE : this.baseUrl

  fetchUserSubscriptions(queryParams = {}) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeUserSubscriptions}`, { params: queryParams })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  createUserSubscription(userSubscriptionData) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(`${this.API_BASE}${routeUserSubscriptions}`, userSubscriptionData)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  fetchUserSubscription(id) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .get(`${this.API_BASE}${routeUserSubscriptions}/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  removeUserSubscription(id) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .delete(`${this.API_BASE}${routeUserSubscriptions}/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  removeUserSubscriptionAll() {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .delete(`${this.API_BASE}${routeUserSubscriptions}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // *** Other actions //

  updateUserSubscription(userSubscriptionData) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .put(`${this.API_BASE}${routeUserSubscriptions}/${userSubscriptionData.id}`, { ...userSubscriptionData })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }

  // { campaignId, label: ["important" | "company" ...] }
  changeUserSubscriptionsFlavors(id, toggleflavor) {
    return new Promise((resolve, reject) => {
      this.axiosIns
        .post(`${this.API_BASE}${routeUserSubscriptions}/flavors/${id}`, { flavor: toggleflavor })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }
}

// Exportamos useUserSubscription (Antes se exportaba la clase y se definia un fichero nuevo para exportar la instancia)
const useUserSubscription = new UserSubscription()
export default useUserSubscription
